import { http } from './config'
import authHeader from './auth-header';

export default {


    

    lancamentos_cartao: (dataI, dataF, nomeEmpresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa
        }
        return http.get('/conciliacao/consulta-recebimento', {
           params: params,
           headers: authHeader()   
        })
    },


}
